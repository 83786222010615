:root {
    --vh: 1vh;
}

body{
    background-color: var(--background-color);
}

* {
    padding: 0px;
    margin: 0px;
    outline: 0px;
    transition: max-width 0.3s;
    font-family: "Poppins";
}

*::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

*::-webkit-scrollbar-track {
    background: rgb(193 193 193);
}

*::-webkit-scrollbar-thumb {
    background-color: #86848D;
    border-radius: 20px;
    border: 3px solid rgb(193 193 193);
}

b {
    font-weight: 600 !important;
}

th {
    font-weight: 600 !important;
}

body {
    margin: 0px !important;
    padding: 0px !important;
    width: 100%;
    font-family: "Poppins";
    /* font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; */
}

@media (min-width: 500px) {
    .notify-card {
        max-width: 400px;
    }
}

@media (min-width: 1000px) {
    .notify-card {
        max-width: 40vw;
    }
}

img {
    object-fit: cover;
}

.text-center {
    text-align: center;
}

.pointer {
    cursor: pointer !important;
}

.cursor-default {
    cursor: default !important;
}

.j-center {
    justify-content: center;
}

.a-center {
    align-items: center;
}

.empty {
    font-style: italic;
    opacity: 0.7;
    font-size: 12px;
}

.no-break {
    overflow: hidden;
    text-overflow: ellipsis;
}

.no-break-label {
    white-space: nowrap;
}

.display-none{
    display: none;
}